import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective, BsModalRef, BsDatepickerConfig } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import * as $ from 'jquery';

import { CompanyLisense, LicenseService, LicenseActionResult } from './../license-shared';
import { HttpErrorHanderService } from '../../shared/http-error-handler.service';
import { BsDatapickerService } from '../../shared/ngx-datepicker-service';
import { Subscribable, Subscription } from 'rxjs';


enum CommonLicenseActionType {
  Lock = 0,
  UnLock = 1,
  Delete = 2,
  SetExpiredOn = 3,
  Reset = 4,
  ReActive = 5
}
interface LockLicenseModel {
  id: number;
  companyName: string;
  code: string;
  note: string;
  mode: CommonLicenseActionType;
  title: string;
  buttonName: string;
  expiredOn?: Date;
  toExpiredOn?: Date;
}
declare var PopupCenter: any;
@Component({
  selector: 'app-common-license',
  templateUrl: './common-license.component.html',
  styleUrls: ['./common-license.component.css'],
  exportAs: "common-licenses"
})
export class CommonLicenseComponent implements OnInit {
  @Input('licenses')
  licenses: CompanyLisense[];
  @Input('isDisplayCompanyName')
  isDisplayCompanyName: boolean = true;

  @Input('isDisplayConsole')
  isDisplayConsole: boolean = true;



  @Output()
  onLicenseChanged = new EventEmitter<CompanyLisense>();

  editingModel: LockLicenseModel
  isSubmiting: boolean = false;
  postErrorMessage: string = null;

  @ViewChild('lockLicenseTemplate') autoShownModal: ModalDirective;
  private bsModelRef: BsModalRef;


  bsConfig: Partial<BsDatepickerConfig>;

  constructor(
    private modalService: BsModalService,
    private licenseService: LicenseService,
    private httpErrorHanderService: HttpErrorHanderService,
    private bsDatapickerService: BsDatapickerService) {

    this.licenses = [];
    this.bsConfig = this.bsDatapickerService.getBsConfig();
    this.bsConfig.minDate = null;
  }

  trackByFn(index: number, item: CompanyLisense) {
    return item.ID;
  }
  popupLockLicense(item: CompanyLisense) {

    this._popupLicense(CommonLicenseActionType.Lock, item);
  }


  popupUnLockLicense(item: CompanyLisense) {
    this._popupLicense(CommonLicenseActionType.UnLock, item);
  }

  popupDeleteLicense(item: CompanyLisense) {
    this._popupLicense(CommonLicenseActionType.Delete, item);
  }
  resetLicenseExpiredOn(item: CompanyLisense) {
    this._popupLicense(CommonLicenseActionType.SetExpiredOn, item);
  }
  resetLicense(item: CompanyLisense) {
    this._popupLicense(CommonLicenseActionType.Reset, item);
  }
  reActiveLicense(item: CompanyLisense) {
    this._popupLicense(CommonLicenseActionType.ReActive, item);
  }



  lockLicenseAction(): void {

    if (this.editingModel.mode == CommonLicenseActionType.Lock) {
      this.licenseService.lockLicense(this.editingModel.id, this.editingModel.note).subscribe(res => {
        var item = this.licenses.filter(s => s.ID == this.editingModel.id)[0];
        item.Status = res.toStatus;
        item.StatusDescription = res.toStatusDescription;
        this.hideLockModal();
      },
        error => {
          this.postErrorMessage = this.httpErrorHanderService.process(error);
        });
    }
    else if (this.editingModel.mode == CommonLicenseActionType.UnLock) {

      this.licenseService.unLockLicense(this.editingModel.id, this.editingModel.note).subscribe(res => {
        this._setCompanyLicense(res);
        this.hideLockModal();
      },
        error => {
          this.postErrorMessage = this.httpErrorHanderService.process(error);
        });
    }
    else if (this.editingModel.mode == CommonLicenseActionType.SetExpiredOn) {
      this.licenseService.resetExpiredOn(this.editingModel.id, this.editingModel.toExpiredOn, this.editingModel.note).subscribe(res => {
        this._setCompanyLicense(res);
        this.hideLockModal();
      },
        error => {
          this.postErrorMessage = this.httpErrorHanderService.process(error);
        });
    }
    else if (this.editingModel.mode == CommonLicenseActionType.Reset) {
      this.licenseService.reset(this.editingModel.id, this.editingModel.toExpiredOn, this.editingModel.note).subscribe(res => {
        this._setCompanyLicense(res);
        this.hideLockModal();
      },
        error => {
          this.postErrorMessage = this.httpErrorHanderService.process(error);
        });
    }
    else if (this.editingModel.mode == CommonLicenseActionType.ReActive) {
      this.licenseService.reActive(this.editingModel.id, this.editingModel.note).subscribe(res => {
        this._setCompanyLicense(res);
        this.hideLockModal();
      },
        error => {
          this.postErrorMessage = this.httpErrorHanderService.process(error);
        });
    }
    else {

      this.licenseService.deleteLicense(this.editingModel.id, this.editingModel.note).subscribe(res => {
        this.licenses = this.licenses.filter(t => t.ID != res.id);
        this.hideLockModal();
      },
        error => {
          this.postErrorMessage = this.httpErrorHanderService.process(error);
        });
    }
  }

  private _setCompanyLicense(result: LicenseActionResult): void {
    let items = this.licenses.filter(s => s.ID == this.editingModel.id);
    if (items.length == 0)
      return;
    let item = items[0];
    item.Status = result.toStatus;
    item.StatusDescription = result.toStatusDescription;
    item.ExpiredOn = result.expiredOn;
    item.Pin = result.Pin;
  }


  private _popupLicense(mode: CommonLicenseActionType, item: CompanyLisense) {
    this.postErrorMessage = null;
    this.editingModel = {
      id: item.ID,
      companyName: item.CompanyName,
      code: item.Code,
      note: "",
      mode: mode,
      title: "",
      buttonName: ""
    };
    if (mode == CommonLicenseActionType.Lock) {
      this.editingModel.title = "锁定授权码";
      this.editingModel.buttonName = "确认锁定";
    }
    else if (mode == CommonLicenseActionType.UnLock) {
      this.editingModel.title = "解锁授权码";
      this.editingModel.buttonName = "确认解锁";
    }
    else if (mode == CommonLicenseActionType.SetExpiredOn) {
      this.editingModel.expiredOn = item.ExpiredOn;
      this.editingModel.title = "调整期限";
      this.editingModel.buttonName = "确认调整有效期限";
    }
    else if (mode == CommonLicenseActionType.Reset) {
      this.editingModel.title = "重置授权码";
      this.editingModel.buttonName = "确认重置";
    }
    else if (mode == CommonLicenseActionType.ReActive) {
      this.editingModel.title = "重新启用授权码";
      this.editingModel.buttonName = "确认重新启用";
    }
    else {
      this.editingModel.title = "删除授权码";
      this.editingModel.buttonName = "确认删除";
    }

    this.bsModelRef = this.modalService.show(this.autoShownModal, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      class: 'modal-dialog-centered'
    });



  }


  showStatusChangeHistory(item: CompanyLisense) {
    let url = `/LicenseDetail/ShowLicenseStatusChangeHistories/?licenseID=${item.ID}`;
    PopupCenter(url, "", 1000, 800);
  }

  showExpiredOnChangeHistory(item: CompanyLisense) {
    let url = `/LicenseDetail/ShowLicenseExpiredOnChangeHistories/?licenseID=${item.ID}`;
    PopupCenter(url, "", 1000, 800);
  }


  showLoginChangeHistory(item: CompanyLisense) {
    let url = `/LicenseDetail/ShowLicenseLoginChangeHistories/?licenseID=${item.ID}`;
    PopupCenter(url, "", 1000, 800);
  }

  ngOnInit() {
    this.eventEventEmitter = this.modalService.onShown.subscribe(t => {
      $("#txtNote").focus();
    });
  }
  hideLockModal(): void {
    this.bsModelRef.hide();
  }
  private eventEventEmitter: Subscription;
  ngOnDestroy() {
    if (this.eventEventEmitter) {
      this.eventEventEmitter.unsubscribe();
    }
  }
}
