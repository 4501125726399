import { Component, ViewChild, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { BsModalService, ModalDirective, BsModalRef, BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { zhCnLocale } from 'ngx-bootstrap/locale';


import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../company/company.service';
import { CompanyModel, CompanyQuereyParameter } from "./../company/company.model";
import { HttpErrorHanderService } from '../../shared/http-error-handler.service';
import { CompanyLisense, LicenseService, LicenseQueryParameter, CreateLicenseModel } from './../license-shared';
import * as $ from "jquery";
import { Subscription } from 'rxjs';




@Component({
  selector: 'app-company-license',
  templateUrl: './company-license.component.html',
  styleUrls: ['./company-license.component.css']
})
export class CompanyLicenseComponent implements OnInit, OnDestroy {

  @ViewChild('template') autoShownModal: ModalDirective;
  private bsModelRef: BsModalRef;

  company: CompanyModel;
  licenses: CompanyLisense[];
  serverErrorMessage: string = null;
  companyID: number;
  createLicenseModel: CreateLicenseModel;
  bsConfig: Partial<BsDatepickerConfig>;
  locale = 'zh-cn';
  postErrorMessage: string;
  constructor(
    private modalService: BsModalService,
    private activedRouter: ActivatedRoute,
    private router: Router,
    private httpErrorHanderService: HttpErrorHanderService,
    private companyService: CompanyService,
    private licenseService: LicenseService,
    private _localeService: BsLocaleService
  ) {

    defineLocale("zh-cn", zhCnLocale);
    _localeService.use(this.locale);
    // let obj: any = {};
    this.licenses = [];
    this.bsConfig = Object.assign({}, { containerClass: "theme-dark-blue", minDate: new Date(), dateInputFormat: "YYYY-MM-DD" });
  }

  ngOnInit() {
    this.companyID = +this.activedRouter.snapshot.queryParams['companyID'];
    this.initCompanyModel();
    this.getLicenses();
    this.eventEventEmitter = this.modalService.onShown.subscribe(t => {
      $("#txtLicenseCount").focus();
    });
  }

  //template
  private eventEventEmitter: Subscription;
  ngOnDestroy() {
    if (this.eventEventEmitter) {
      this.eventEventEmitter.unsubscribe();
    }
  }
  private initCompanyModel(): void {
    let queryParameter: CompanyQuereyParameter = {
      CompanyID: this.companyID
    };
    this.companyService.queryCompanies(queryParameter).subscribe(res => {
      if (res.length == 0) {
        this.serverErrorMessage = "Invalid companyID";
        return;
      }
      this.company = res[0];
    },
      error => {
        this.serverErrorMessage = this.httpErrorHanderService.process(error);

      });
  }
  private getLicenses(): void {
    let queryParameter: LicenseQueryParameter = {
      CompanyID: this.companyID
    };
    this.licenseService.getLicenses(queryParameter).subscribe(res => {
      this.licenses = res;
    },
      error => {
        this.serverErrorMessage = this.httpErrorHanderService.process(error);

      });
  }
  isSubmiting: boolean = false;

  popupCreateCompany(): void {
    this.postErrorMessage = null;
    this.createLicenseModel = {
      CompanyID: this.company.ID,
      CompanyName: this.company.Name
    };
    this.bsModelRef = this.modalService.show(this.autoShownModal, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: true,
      class: 'modal-dialog-centered'
    });
  }
  exportexcel() {
    let queryParameter: LicenseQueryParameter = {
      CompanyID: this.companyID
    };
    this.licenseService.prepareDownload(queryParameter).subscribe((res: any) => {
      var key = res.Key;
      window.location.href = "/api/CompanyManager/DownloadLicenses/?key=" + key;
    },
      error => {
        // this.serverErrorMessage = this.httpErrorHanderService.process(error);
      });
  }
  createLicenses() {
    this.isSubmiting = true;
    this.licenseService.createLicenses(this.createLicenseModel).subscribe(res => {
      this.isSubmiting = false;
      this.getLicenses();
      this.hideModal();
    },
      error => {
        this.postErrorMessage = this.httpErrorHanderService.process(error);
        this.isSubmiting = false;
      });
  }

  private onAlertClosed() {
    this.serverErrorMessage = null;
  }


  hideModal() {
    this.bsModelRef.hide();
  }
}
