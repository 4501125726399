import { Component ,OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from './login/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit  {
  title = 'app';
  hasLogin: boolean;
  constructor(private router: Router, private loginService: LoginService) {
    if(!window.location.href.endsWith("/login")){
        loginService.callRemoteAccess().subscribe(hasLogin=>{
            this.hasLogin = hasLogin;
            if(!this.hasLogin )
            this.logoff();
        });
    }
    
    loginService.hasLoginObserviable.subscribe(res => {
      if (res == null || res == false)
        this.hasLogin = false
      else
        this.hasLogin = true
    });
 
  }

  ngOnInit(){
  }

  logoff() {
    this.loginService.callLoginOff();
    this.router.navigate(['/login']);
  }
}
