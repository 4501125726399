import { Component, OnInit } from '@angular/core';
import { LicenseService, CompanyLisense, CompanyLicenseCount } from '../license-shared';
import { HttpErrorHanderService } from '../../shared/http-error-handler.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  licenses: CompanyLisense[];
  companyLicenseCount: CompanyLicenseCount[];
  errorMessage: string;
  constructor(private licenseService: LicenseService,
    private httpErrorHanderService: HttpErrorHanderService,
    private router: Router) {
    this.licenses = [];
    this.companyLicenseCount = [];
  }
  gotoCompany(item: CompanyLicenseCount) {
    this.router.navigateByUrl("/company-license?companyID=" + item.CompanyID);
  }
  ngOnInit() {
    let sub = this.licenseService.getLatestLicenses().subscribe(res => {
      this.licenses = res;
    }, (error) => {
      this.httpErrorHanderService.process(error);
    },
      () => { sub.unsubscribe(); })


    let sub2 = this.licenseService.getCompanyLicenseStatics().subscribe(res => {
      this.companyLicenseCount = res;
    }, (error) => {
      this.httpErrorHanderService.process(error);
    },
      () => { sub2.unsubscribe(); })
  }
}
