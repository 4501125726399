import { Component, ViewChild, OnInit, OnDestroy, EventEmitter } from '@angular/core';

import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { UnkownSoundKeyword,EditUnkownSoundKeyword } from './unkown-sound-keywords.models';

import {EnumValue, SoundKeywordType,SoundKeywordTypeList, BadRequestReason} from "./../../shared"
import { UnknownSoundKeywordsService } from './unkown-sound-keywords.service';
import { debug } from 'util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unknown-sound-keywords',
  templateUrl: './unknown-sound-keywords.component.html',
  styleUrls: ['./unknown-sound-keywords.component.css']
})
export class UnknownSoundKeywordsComponent implements OnDestroy, OnInit {

  @ViewChild('template') autoShownModal: ModalDirective;
  private bsModelRef: BsModalRef;

  isSubmiting: boolean = false;

  editingModel: EditUnkownSoundKeyword ={} as any;
  submittingText: string;
  postErrorMessage: string = null;
  allKeywordsTypes: EnumValue<SoundKeywordType>[] = SoundKeywordTypeList;
  keywords: UnkownSoundKeyword[];
  queryErrorMessage: string;
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private service: UnknownSoundKeywordsService
  ) {
    this.editingModel = {} as any;
    this.allKeywordsTypes = [
      { Value: null, Description: "忽略不处理" },
      ...
      SoundKeywordTypeList
    ]

  }
  ngOnInit() {
    this.eventEventEmitter = this.modalService.onShown.subscribe(t => {
      $("#txtCompanyName").focus();
    });
    this.queryKeywords();
  }


  private eventEventEmitter: Subscription;

  trackByFn(index: number, item: UnkownSoundKeyword) {
    return item.ID;
  }

  private _popupModal() {
    this.submittingText = "保存";
    this.isSubmiting = false;
    this.bsModelRef = this.modalService.show(this.autoShownModal, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: false,
      class: 'modal-dialog-centered'
    });
  }

  editKeyword(item:UnkownSoundKeyword){
    this.editingModel = {} as EditUnkownSoundKeyword;
    this.editingModel.OriginalKey = item.Keyword;
    this.editingModel.EditModel = item;

    this._popupModal()
  }

  setKeywrods() {
    debugger;
    this.isSubmiting = true;
    this.postErrorMessage = null;
    this.submittingText += "...";
    this.service.updateKeyword(this.editingModel).subscribe(res => {
      this.bsModelRef.hide();
      this.keywords = this.keywords.filter(t=>t.ID!= this.editingModel.EditModel.ID);
    }, error => {
      this.submittingText = "保存";
      this.isSubmiting = false;
      if (error.error) {
        let badRequestParameter: BadRequestReason = error.error;
        this.postErrorMessage = badRequestParameter.ErrorMessage;
      }
      else {
        this.postErrorMessage = JSON.stringify(error);
      }
    });
  }

 

  queryKeywords() {

    this.service.queryKeywords().subscribe(res => {

      this.keywords = res;
    }, error => {
      if (error.error) {
        let badRequestParameter: BadRequestReason = error.error;
        this.queryErrorMessage = badRequestParameter.ErrorMessage;
      }
      else {
        this.queryErrorMessage = error;
      }
    });
  }


  ngOnDestroy() {
    if (this.eventEventEmitter) {
      this.eventEventEmitter.unsubscribe();
    }
  }

  hideModal() {
    this.bsModelRef.hide();
  }
}
