import { Component, ViewChild, OnInit, OnDestroy, EventEmitter } from '@angular/core';

import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as $ from 'jquery';

import { EnumValue, SoundKeywordType, SoundKeywordTypeList, BadRequestReason } from "./../../shared"
import { SoundKeywordsService } from './sound-keywords.service';
import { debug } from 'util';
import { Soundkeyword, QueryParameter } from './sound-keywords.models';
import { Subscription } from 'rxjs';


@Component({
  selector: 'sound-keywords',
  templateUrl: './sound-keywords.component.html',
  styleUrls: ['./sound-keywords.component.css']
})
export class SoundKeywordsComponent implements OnDestroy, OnInit {

  @ViewChild('template') autoShownModal: ModalDirective;
  private bsModelRef: BsModalRef;

  isSubmiting: boolean = false;


  createCompanyTitle: string;
  submittingText: string;
  postErrorMessage: string = null;


  allSoundKeyTypes: EnumValue<SoundKeywordType>[];

  keywords: Soundkeyword[];
  editModel: Soundkeyword = {} as any;
  queryParameter: QueryParameter = {} as any;

  queryErrorMessage: string;
  lastType: number;
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private service: SoundKeywordsService
  ) {

    this.allSoundKeyTypes = SoundKeywordTypeList;
    this.lastType = this.allSoundKeyTypes[0].Value;
  }
  ngOnInit() {
    this.eventEventEmitter = this.modalService.onShown.subscribe(t => {
      $("#txtKeyword").focus();
    });
    this.queryKeywords();
  }


  queryKeywords() {
    this.service.queryKeywords().subscribe(res => {
      this.keywords = res;
    }, error => {

      if (error.error) {
        let badRequestParameter: BadRequestReason = error.error;
        this.queryErrorMessage = badRequestParameter.ErrorMessage;
      }

      else {
        this.queryErrorMessage = JSON.stringify(error);
      }
    });
  }
  private eventEventEmitter: Subscription;
  popupCreateKeyword() {
    let any: any = {};
    this.editModel = any;
    this.editModel.ID = 0;
    this.editModel.Type = this.lastType;
    this._popupModal();

  }

  private _popupModal() {
    this.submittingText = "保存";
    this.isSubmiting = false;
    if (this.editModel.ID <= 0) {
      this.createCompanyTitle = "创建语音指令";
    }
    else
      this.createCompanyTitle = "修改语音指令";
    this.bsModelRef = this.modalService.show(this.autoShownModal, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: false,
      class: 'modal-dialog-centered'
    });
  }
  trackByFn(index: number, item: Soundkeyword) {
    return item.ID;
  }


  createKeyword() {
    this.lastType = this.editModel.Type;
    this.isSubmiting = true;
    this.postErrorMessage = null;
    this.submittingText += "...";
    this.service.setkownSoundKeyword(this.editModel).subscribe(res => {
      this.bsModelRef.hide();
      this.keywords = res;
    }, error => {
      this.submittingText = "保存";
      if (this.editModel.ID <= 0) {
        this.createCompanyTitle = "创建语音指令";
      }
      else
        this.createCompanyTitle = "修改语音指令";

      this.isSubmiting = false;
      if (error.error) {
        let badRequestParameter: BadRequestReason = error.error;
        this.postErrorMessage = badRequestParameter.ErrorMessage;
      }

      else {
        this.postErrorMessage = JSON.stringify(error);
      }
    });
  }

  editKeyword(item: Soundkeyword): void {
    this.editModel = <Soundkeyword>Object.assign({}, item);
    this._popupModal();
  }
  archiveKeyword(item: Soundkeyword): void {
    if (!window.confirm("确认删除吗?"))
      return;
    this.service.archveSoundKeyword(item).subscribe(res => {
      this.keywords = res;
    }, error => {
      if (error.error) {
        let badRequestParameter: BadRequestReason = error.error;
        this.postErrorMessage = badRequestParameter.ErrorMessage;
      }
      else {
        this.postErrorMessage = JSON.stringify(error);
      }
    });
  }


  ngOnDestroy() {
    if (this.eventEventEmitter) {
      this.eventEventEmitter.unsubscribe();
    }
  }

  hideModal() {
    this.bsModelRef.hide();
  }
}
