import { EnumValue } from "./CommonEnumValue";
export enum CompanyStatus {
  Created = 0,
  Online = 5,
  Paused = 10,
  Offline = 15

}
export const CompanyStatusList: EnumValue<CompanyStatus>[] = [
  { Value: CompanyStatus.Created, Description: "创建" },
  { Value: CompanyStatus.Online, Description: "运行中" },
  { Value: CompanyStatus.Paused, Description: "暂停" },
  { Value: CompanyStatus.Offline, Description: "停止合作" }
];

