import { Component, ViewChild, OnDestroy, AfterViewInit, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { UserLoginParameter } from "./UserLoginParameter";


import * as $ from 'jquery';
import { debug } from "util";

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BadRequestReason } from "./../../shared/BadRequestReason";
import { LoginService } from "./login.service";
import { ModalDirective } from "angular-bootstrap-md";

@Component({
  selector: `victory-login`,
  templateUrl: `./login.component.html`,
  styleUrls: ['./login.component.css'],
  moduleId: module.id
})
export class LoginComponent {

  username: string;
  password: string;
  loginErrorMessage: string;
  isSubmiting: boolean;
  submittingText: string;
  @ViewChild("frame") template: ModalDirective;

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    private router: Router,
    private loginService: LoginService) {
    this.isSubmiting = false;
    this.submittingText = "确认登录";
  }

 
  userLogin(): void {
    this.loginErrorMessage = "";
    this.isSubmiting = true;
    this.submittingText = "正在验证...";
    let url = "/api/Login/Verify";
    let requestBody: UserLoginParameter = {
      Username: this.username,
      Password: this.password

    };
    this.http.post(url, requestBody).subscribe(res => {
      this.loginService.callHasLogin();
      this.template.hide();
      this.router.navigate(['/home']);
    }, error => {
      this.submittingText = "确认登录";
      this.isSubmiting = false;
      if (error.error) {
        let badRequestParameter: BadRequestReason = error.error;
        this.loginErrorMessage = badRequestParameter.ErrorMessage;
      }
      else {
        this.loginErrorMessage = error;
      }
    });

  }
  onOpened($event: any) {
    $("#txtUsername").focus();
  }
  //private eventEventEmitter: EventEmitter<any>;
  //openModal() {
  //  this.eventEventEmitter = this.modalService.onShown.subscribe(t => {
  //    $("#txtUsername").focus();
  //  });
  //  this.bsModelRef = this.modalService.show(this.template, {
  //    backdrop: 'static',
  //    keyboard: false,
  //    focus: true,
  //    animated: false,
  //    class: 'modal-dialog-centered'
  //  });
  //}
  //ngOnDestroy() {
  //  if (this.eventEventEmitter) {
  //    this.eventEventEmitter.unsubscribe();
  //  }
}

