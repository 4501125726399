import { Injectable } from "@angular/core";
import { defineLocale } from 'ngx-bootstrap/chronos';
import { zhCnLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap';

@Injectable()
export class BsDatapickerService {
  bsConfig: Partial<BsDatepickerConfig>;
  locale = 'zh-cn';
  constructor(private _localeService: BsLocaleService) {
    defineLocale("zh-cn", zhCnLocale);
    _localeService.use(this.locale);
    this.bsConfig = Object.assign({}, { containerClass: "theme-dark-blue", minDate: new Date(), dateInputFormat: "YYYY-MM-DD" });
  }


  public getBsConfig(): Partial<BsDatepickerConfig> {
    return this.bsConfig;
  }
}
