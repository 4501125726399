import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";


import { Observable } from "rxjs/Observable";
import { SoundKeywordType } from "../../shared/SoundKeywordType";
import { UnkownSoundKeyword, EditUnkownSoundKeyword } from "./unkown-sound-keywords.models";


@Injectable()
export class UnknownSoundKeywordsService {
    constructor(private http: HttpClient) {

    }

    public queryKeywords(): Observable<UnkownSoundKeyword[]> {
        let url = "/api/Soundkeyword/QueryUnkownSoundKeywords";
        let requestBody = {};
        return this.http.post<UnkownSoundKeyword[]>(url, requestBody);
    }

    public updateKeyword(model: EditUnkownSoundKeyword): Observable<any> {

        var requestBody = {
            ID: model.EditModel.ID,
            ToType: model.SelectedType,
            OriginalKey: model.OriginalKey,
            ReplacingKey: model.ReplacingKey
        };
        let url = "/api/Soundkeyword/SetUnkownSoundKeyword";

        return this.http.post<any>(url, requestBody);
    }



}
