import { Injectable } from "@angular/core";
import { BadRequestReason } from "./BadRequestReason";

@Injectable()
export class HttpErrorHanderService {

  public process(error: any): string {
    if (error.error) {
      let badRequestParameter: BadRequestReason = error.error;
      return badRequestParameter.ErrorMessage;
    }

    else {
      return JSON.stringify(error);
    }
  }
}
