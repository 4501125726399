import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EditingCompanyModel, CompanyModel, CompanyQuereyParameter } from "./company.model";

import { Observable } from "rxjs";


@Injectable()
export class CompanyService {
  constructor(private http: HttpClient) {

  }


  public createUpdateCompany(companyModel: EditingCompanyModel): Observable<CompanyModel[]> {
    let url = "/api/CompanyManager/CreateOrUpdateCompany";
    let requestBody = companyModel;
    return this.http.post<CompanyModel[]>(url, requestBody);
  }



  public queryCompanies(queryParameter: CompanyQuereyParameter): Observable<CompanyModel[]> {
    let url = "/api/CompanyManager/QueryCompanies";
    let requestBody = queryParameter;
    return this.http.post<CompanyModel[]>(url, requestBody);
  }
}
