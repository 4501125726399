import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";


import { Observable } from "rxjs/Observable";
import { SoundKeywordType } from "../../shared/SoundKeywordType";
import { Soundkeyword } from "./sound-keywords.models";



@Injectable()
export class SoundKeywordsService {
    constructor(private http: HttpClient) {

    }

    public queryKeywords(): Observable<Soundkeyword[]> {
        let url = "/api/Soundkeyword/GetkownSoundKeywords";
        let requestBody = {};
        return this.http.post<Soundkeyword[]>(url, requestBody);
    }

    public setkownSoundKeyword(model: Soundkeyword): Observable<Soundkeyword[]> {
        let url = "/api/Soundkeyword/SetkownSoundKeyword";
        let requestBody = model;
        return this.http.post<Soundkeyword[]>(url, requestBody);
    }


    public archveSoundKeyword(model: Soundkeyword): Observable<Soundkeyword[]> {
        let url = "/api/Soundkeyword/ArchveSoundKeyword?id="+ model.ID.toString();
        let requestBody = {};
        return this.http.post<Soundkeyword[]>(url, requestBody);
    }

}
