import { EnumValue } from "./CommonEnumValue";
export enum SoundKeywordType {
  Redo = 100,
  Replacing = 200,

}
export const SoundKeywordTypeList: EnumValue<SoundKeywordType>[] = [
  { Value: SoundKeywordType.Redo, Description: "重读" },
  { Value: SoundKeywordType.Replacing, Description: "替换" }
];
