import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";




@Injectable()
export class LoginService {

  hasLoginObserviable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  constructor(private http: HttpClient) {

  }


  public callHasLogin() {
    this.hasLoginObserviable.next(true);
  }

  public callLoginOff() {
    this.hasLoginObserviable.next(false);
  }

  public callRemoteAccess(): Observable<boolean> {

    let url = "/api/Login/IsLogin";
    var requestBody = {} as any;
    return this.http.post(url, requestBody) as Observable<boolean>

  }
}
