import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LicenseQueryParameter, CompanyLisense, CreateLicenseModel, LicenseActionResult, CompanyLicenseCount } from "./license.model";
import { Observable } from "rxjs/Observable";





@Injectable()
export class LicenseService {
  constructor(private http: HttpClient) {

  }
  public getLicenses(queryParameter: LicenseQueryParameter): Observable<CompanyLisense[]> {

    let url = "/api/CompanyManager/QueryLicenses";
    let requestBody = queryParameter;
    return this.http.post<CompanyLisense[]>(url, requestBody);

  }

  public createLicenses(parameter: CreateLicenseModel): Observable<any> {
    let url = "/api/CompanyManager/CreateLicense";
    let requestBody = parameter;
    return this.http.post<any>(url, requestBody);
  }

  public lockLicense(id: number, note: string): Observable<LicenseActionResult> {
    var parameter = {
      ID: id,
      Note: note
    };

    let url = "/api/CompanyManager/LockLicense";
    let requestBody = parameter;
    return this.http.post<LicenseActionResult>(url, requestBody);
  }

  public unLockLicense(id: number, note: string): Observable<LicenseActionResult> {
    var parameter = {
      ID: id,
      Note: note
    };

    let url = "/api/CompanyManager/UnLockLicense";
    let requestBody = parameter;
    return this.http.post<LicenseActionResult>(url, requestBody);
  }

  public deleteLicense(id: number, note: string): Observable<LicenseActionResult> {
    var parameter = {
      ID: id,
      Note: note
    };

    let url = "/api/CompanyManager/DeleteLicense";
    let requestBody = parameter;
    return this.http.post<LicenseActionResult>(url, requestBody);
  }

  public resetExpiredOn(id: number, toExpiredOn: Date, note: string): Observable<LicenseActionResult> {
    var parameter = {
      ID: id,
      Note: note,
      ExpiredOn: toExpiredOn
    };

    let url = "/api/CompanyManager/SetExpiredOn";
    let requestBody = parameter;
    return this.http.post<LicenseActionResult>(url, requestBody);
  }

  public reset(id: number, toExpiredOn: Date, note: string): Observable<LicenseActionResult> {
    var parameter = {
      ID: id,
      Note: note,
      ExpiredOn: toExpiredOn
    };

    let url = "/api/CompanyManager/Reset";
    let requestBody = parameter;
    return this.http.post<LicenseActionResult>(url, requestBody);
  }

  public reActive(id: number, note: string): Observable<LicenseActionResult> {
    var parameter = {
      ID: id,
      Note: note
    };

    let url = "/api/CompanyManager/ReActive";
    let requestBody = parameter;
    return this.http.post<LicenseActionResult>(url, requestBody);
  }


  public getLatestLicenses(): Observable<CompanyLisense[]> {

    let url = "/api/CompanyManager/GetLatestLicenses";
    return this.http.get<CompanyLisense[]>(url);
  }
  public getCompanyLicenseStatics(): Observable<CompanyLicenseCount[]> {

    let url = "/api/CompanyManager/GetCompanyLicenseStatics";
    return this.http.get<CompanyLicenseCount[]>(url);
  }

  public prepareDownload(queryParameter: LicenseQueryParameter): Observable<any> {

    let url = "/api/CompanyManager/PrepareDownload";
    let requestBody = queryParameter;
    return this.http.post<any>(url, requestBody);

  }

}
