import { EnumValue } from "./CommonEnumValue";
export enum LicenseStatus {
  Created = 0,
  Actived = 5,
  Locked = 10,
  Expired = 15,
  Deleted = 20

}
export const LicenseStatusList: EnumValue<LicenseStatus>[] = [
  { Value: LicenseStatus.Created, Description: "创建" },
  { Value: LicenseStatus.Actived, Description: "已激活" },
  { Value: LicenseStatus.Locked, Description: "被锁定" },
  { Value: LicenseStatus.Expired, Description: "已超期" },
  { Value: LicenseStatus.Deleted, Description: "被删除" }
];
