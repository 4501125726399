import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AngularFontAwesomeModule } from 'angular-font-awesome';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CompanyComponent } from "./company/company.component";
import { CompanyService } from "./company/company.service";
import { ReportsComponent } from "./reports/reports.component";


import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';



import { LoginComponent } from "./login/login.component";

import { CompanyLicenseComponent } from './company-license/company-license.component';
import { LicenseService } from './license-shared';
import { HttpErrorHanderService } from "./../shared/http-error-handler.service";
import { CommonLicenseComponent } from "./common-license/common-license.component";
import { LicenseListComponent } from "./license-list/license-list.component";
import { BsDatapickerService } from "./../shared/ngx-datepicker-service";
import { LoginService } from './login/login.service';

import { UnknownSoundKeywordsComponent } from "./unknown-sound-keywords/unknown-sound-keywords.component"
import { UnknownSoundKeywordsService } from "./unknown-sound-keywords/unkown-sound-keywords.service";
import { SoundKeywordsComponent } from './sound-keywords/sound-keywords.component';
import { SoundKeywordsService } from './sound-keywords/sound-keywords.service';





@NgModule({
  declarations: [
    AppComponent,
    CompanyComponent,
    HomeComponent,
    ReportsComponent,
    LoginComponent,
    CompanyLicenseComponent,
    CommonLicenseComponent,
    LicenseListComponent,
    UnknownSoundKeywordsComponent,
    SoundKeywordsComponent
  ],
  imports: [
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AngularFontAwesomeModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent, pathMatch: 'full' },
      { path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: 'company-license', component: CompanyLicenseComponent },
      { path: 'company', component: CompanyComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'license-list', component: LicenseListComponent },
      { path: 'unknown-sound-keywords', component: UnknownSoundKeywordsComponent },
      { path: 'sound-keywords', component: SoundKeywordsComponent }
    ])
  ],
  providers: [
    LoginService,
    CompanyService,
    LicenseService,
    HttpErrorHanderService,
    BsDatapickerService,
    UnknownSoundKeywordsService,
    SoundKeywordsService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
