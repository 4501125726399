import { Component } from '@angular/core';
import { LicenseStatus, LicenseStatusList, EnumValue } from "./../../shared"
import { QueryLicenseModel } from "./license-list.model";
import { HttpErrorHanderService } from '../../shared/http-error-handler.service';
import { LicenseService, LicenseQueryParameter, CompanyLisense } from '../license-shared';


@Component({
  selector: 'app-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.css']
})

export class LicenseListComponent {

  licenseStatusList: EnumValue<LicenseStatus>[];
  queryModel: QueryLicenseModel;
  licenses: CompanyLisense[];
  serverErrorMessage: string;
  constructor(
    private httpErrorHanderService: HttpErrorHanderService,
    private licenseService: LicenseService,
  ) {

    let any: any = {};
    this.queryModel = any;
    this.queryModel.Status = null;
    this.licenseStatusList = [
      { Value: null, Description: "全部" },
      ...
      LicenseStatusList
    ]
    this.licenseStatusList = this.licenseStatusList.filter(t => t.Value != LicenseStatus.Deleted);
    this.licenses = [];
  }

  exportexcel() {
    var queryParameter: LicenseQueryParameter = {
      CompanyName: this.queryModel.CompanyName,
      Code: this.queryModel.LicenseCode,
      IDCode: this.queryModel.IDCode,
      Status: this.queryModel.Status
    };
    this.licenseService.prepareDownload(queryParameter).subscribe((res:any) => {
      var key = res.Key;
      window.location.href = "/api/CompanyManager/DownloadLicenses/?key=" + key;
    },
      error => {
        this.serverErrorMessage = this.httpErrorHanderService.process(error);
      });
  }

  queryLicense() {
    var queryParameter: LicenseQueryParameter = {
      CompanyName: this.queryModel.CompanyName,
      Code: this.queryModel.LicenseCode,
      IDCode: this.queryModel.IDCode,
      Status: this.queryModel.Status
    };
    this.licenseService.getLicenses(queryParameter).subscribe(res => {
      this.licenses = res;
    },
      error => {
        this.serverErrorMessage = this.httpErrorHanderService.process(error);
      });
  }
}
