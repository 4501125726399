import { Component, ViewChild, OnInit, OnDestroy, EventEmitter } from '@angular/core';

import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { CompanyModel, CompanyQuereyParameter, EditingCompanyModel } from './company.model';

import { EnumValue, CompanyStatus, CompanyStatusList, BadRequestReason } from "./../../shared"
import { CompanyService } from './company.service';
import { debug } from 'util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnDestroy, OnInit {

  @ViewChild('template') autoShownModal: ModalDirective;
  private bsModelRef: BsModalRef;

  isSubmiting: boolean = false;

  editingCompanyModel: EditingCompanyModel;
  createCompanyTitle: string;
  submittingText: string;
  postErrorMessage: string = null;

  companyStatusList: EnumValue<CompanyStatus>[] = CompanyStatusList;
  allCompanyStatusList: EnumValue<CompanyStatus>[];
  queryParameter: CompanyQuereyParameter;

  companies: CompanyModel[];
  queryErrorMessage: string;
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private service: CompanyService
  ) {
    let any: any = {};
    this.editingCompanyModel = any;


    this.queryParameter = {
      Status: null
    };

    this.allCompanyStatusList = [
      { Value: null, Description: "全部" },
      ...
      CompanyStatusList
    ]

  }
  ngOnInit() {
    this.eventEventEmitter = this.modalService.onShown.subscribe(t => {
      $("#txtCompanyName").focus();
    });
    this.queryCompanies();
  }


  private eventEventEmitter: Subscription;
  popupCreateCompany() {
    let any: any = {};
    this.editingCompanyModel = any;
    this.editingCompanyModel.ID = 0;

    this._popupModal();

  }
  trackByFn(index: number, item: CompanyModel) {
    return item.ID;
  }

  private _popupModal() {
    this.submittingText = "保存";
    this.isSubmiting = false;
    if (this.editingCompanyModel.ID <= 0) {
      this.createCompanyTitle = "创建客户公司";
    }
    else
      this.createCompanyTitle = "修改客户公司";
    this.bsModelRef = this.modalService.show(this.autoShownModal, {
      backdrop: 'static',
      keyboard: false,
      focus: true,
      animated: false,
      class: 'modal-dialog-centered'
    });
  }
  createCompany() {
    this.isSubmiting = true;
    this.postErrorMessage = null;
    this.submittingText += "...";
    this.service.createUpdateCompany(this.editingCompanyModel).subscribe(res => {
      this.bsModelRef.hide();
      this.companies = res;
    }, error => {
      this.submittingText = "保存";
      if (this.editingCompanyModel.ID <= 0) {
        this.createCompanyTitle = "创建客户公司";
      }
      else
        this.createCompanyTitle = "修改客户公司";

      this.isSubmiting = false;
      if (error.error) {
        let badRequestParameter: BadRequestReason = error.error;
        this.postErrorMessage = badRequestParameter.ErrorMessage;
      }

      else {
        this.postErrorMessage = JSON.stringify(error);
      }
    });
  }

  editCompany(item: CompanyModel): void {
    this.editingCompanyModel = <EditingCompanyModel>Object.assign({}, item);
    this._popupModal();
  }
  viewCompanyLicense(item: CompanyModel) {
    this.router.navigateByUrl(`/company-license?companyID=${item.ID}`);

  }

  queryCompanies() {

    this.service.queryCompanies(this.queryParameter).subscribe(res => {

      this.companies = res;
    }, error => {

      debugger;

      if (error.error) {
        let badRequestParameter: BadRequestReason = error.error;
        this.queryErrorMessage = badRequestParameter.ErrorMessage;
      }
      else {
        this.queryErrorMessage = error;
      }
    });
  }


  ngOnDestroy() {
    if (this.eventEventEmitter) {
      this.eventEventEmitter.unsubscribe();
    }
  }

  hideModal() {
    this.bsModelRef.hide();
  }
}
