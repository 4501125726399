﻿import { Component } from '@angular/core';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
/** reports component*/
export class ReportsComponent {
    /** reports ctor */
    constructor() {

    }
}